import styled from 'styled-components'

export const PostBody = styled.div`
  max-width: 600px;
  margin: 0 auto;
  font-size: 1.2rem;
`

export const Wrapper = styled.article`
  margin: 3rem 0 7rem;
`
