import React, { useState } from 'react'
import { StructuredText } from 'react-datocms'
import Img from 'gatsby-image'
import { PostBody, Wrapper } from './SinglePost.style'
import Layout from '../../../layouts/layout'
import Container from '../../../layouts/Container/Container'
import PostTitle from './PostTitle/PostTitle'
import BackToTop from '../../core/BackToTop/BackToTop'
import { getCurrentPath } from '../../../libs'
import Contact from '../../Contact/Contact'

const SinglePost = ({ content, title, coverImage, date }) => {
  let currentPath
  if (typeof window !== 'undefined') {
    currentPath = getCurrentPath()
  }
  return (
    <Layout displayArticlesMenu slimHeader>
      <Wrapper>
        <Container>
          <PostTitle title={title} coverImage={coverImage} date={date} />
          <PostBody>
            <StructuredText
              data={content}
              renderBlock={({ record }) => {
                if (record.__typename === 'DatoCmsImageBlock') {
                  return <Img fluid={record.image.fluid} />
                }

                return (
                  <>
                    <p>Don't know how to render a block!</p>
                    <pre>{JSON.stringify(record, null, 2)}</pre>
                  </>
                )
              }}
            />
          </PostBody>
        </Container>
      </Wrapper>
      <Contact hideBackToTop />
      <BackToTop element={`${currentPath}#page-top`} />
    </Layout>
  )
}

export default SinglePost
